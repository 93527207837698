import React, { useContext, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as API from '../utils/api.services';
import Constant, { Codes } from '../config/constant';
import { TOAST_ERROR, TOAST_SUCCESS } from '../config/common';
import { CONFIRM_PASSWORD_VALIDATION, PASSWORD_VALIDATION } from '../config/validation';
import { encrypt } from '../utils/encrypt.decrypt';
import { UserContext } from '../Context/UserData';

const ChangePassword = () => {

    const { updateUser } = useContext(UserContext)
    const closeRef = useRef();

    const [passwordVisible, setPasswordVisible] = useState({
        currentPassword: false,
        newPassword: false,
        confirmPassword: false,
    });
    const { register, handleSubmit, formState: { errors }, reset , watch} = useForm({ mode: "onChange" });

    const togglePasswordVisibility = (field) => {
        setPasswordVisible((prev) => ({
            ...prev,
            [field]: !prev[field],
        }));
    };

    const onSubmit = (data) => {

        API.changePassword(data).then((r) => {
            if (r?.code === Codes.SUCCESS) {
                localStorage.setItem(Constant.AUTH_KEY, encrypt(r?.data))
                updateUser()
                closeRef.current?.click()
                TOAST_SUCCESS(r?.message);
                reset();
            } else {
                TOAST_ERROR(r?.message);
            }
        });
    };

    return (
        <div
            id="change-password"
            className="modal fade"
            tabIndex="-1"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-scrollable modal-md">
                <div className="modal-content ">
                    <div className="modal-body">
                        <div className="text-center mt-2 mb-4">
                            <a href="index.html" className="text-success">
                                <span>
                                    <img
                                        src={Constant.APP_LOGO}
                                        className="me-3"
                                        width="200"
                                        alt=""
                                    />
                                </span>
                            </a>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)} className='p-3'>
                            {/* Current Password Field */}
                            <div className="mb-3">
                                <label htmlFor="currentPassword" className="form-label">Current Password</label>
                                <div className="position-relative">
                                    <input
                                        type={passwordVisible.currentPassword ? 'text' : 'password'}
                                        className="form-control"
                                        id="currentPassword"
                                        {...register('old_password', PASSWORD_VALIDATION())}
                                    />
                                    <i
                                        className={`ti ${passwordVisible.currentPassword ? 'ti-eye' : 'ti-eye-off'}`}
                                        onClick={() => togglePasswordVisibility('currentPassword')}
                                        style={{
                                            position: 'absolute',
                                            top: '50%',
                                            right: '10px',
                                            transform: 'translateY(-50%)',
                                            cursor: 'pointer',
                                            color: '#6c757d',
                                            fontSize: '1.2rem',
                                        }}
                                    ></i>
                                </div>
                                {errors.old_password && (
                                    <div className="error-message pt-1">
                                        {errors.old_password.message}
                                    </div>
                                )}
                            </div>

                            {/* New Password Field */}
                            <div className="mb-4">
                                <label htmlFor="newPassword" className="form-label">New Password</label>
                                <div className="position-relative">
                                    <input
                                        type={passwordVisible.newPassword ? 'text' : 'password'}
                                        className="form-control"
                                        id="newPassword"
                                        {...register('new_password', PASSWORD_VALIDATION())}
                                    />
                                    <i
                                        className={`ti ${passwordVisible.newPassword ? 'ti-eye' : 'ti-eye-off'}`}
                                        onClick={() => togglePasswordVisibility('newPassword')}
                                        style={{
                                            position: 'absolute',
                                            top: '50%',
                                            right: '10px',
                                            transform: 'translateY(-50%)',
                                            cursor: 'pointer',
                                            color: '#6c757d',
                                            fontSize: '1.2rem',
                                        }}
                                    ></i>
                                </div>
                                {errors.new_password && (
                                    <div className="error-message pt-1">
                                        {errors.new_password.message}
                                    </div>
                                )}
                            </div>

                            {/* Confirm Password Field */}
                            <div className="mb-4">
                                <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
                                <div className="position-relative">
                                    <input
                                        type={passwordVisible.confirmPassword ? 'text' : 'password'}
                                        className="form-control"
                                        id="confirmPassword"
                                        {...register('confirm_new_password', CONFIRM_PASSWORD_VALIDATION(watch('new_password')))}
                                    />
                                    <i
                                        className={`ti ${passwordVisible.confirmPassword ? 'ti-eye' : 'ti-eye-off'}`}
                                        onClick={() => togglePasswordVisibility('confirmPassword')}
                                        style={{
                                            position: 'absolute',
                                            top: '50%',
                                            right: '10px',
                                            transform: 'translateY(-50%)',
                                            cursor: 'pointer',
                                            color: '#6c757d',
                                            fontSize: '1.2rem',
                                        }}
                                    ></i>
                                </div>
                                {errors.confirm_new_password && (
                                    <div className="error-message pt-1">
                                        {errors.confirm_new_password.message}
                                    </div>
                                )}
                            </div>

                            {/* Submit Button */}
                            <button type="submit" className="btn btn-primary w-100 py-8 mb-4 rounded-2">
                                Update Password
                            </button>
                            <button
                              type="button"
                              className="d-none btn btn-light-danger text-danger font-medium"
                              data-bs-dismiss="modal"
                              ref={closeRef}
                            >
                              Close
                            </button>
                        </form>

                    </div>
                </div>
                {/* /.modal-content */}
            </div>
            {/* /.modal-dialog */}
        </div>


        // <div
        //   className="page-wrapper"
        //   id="main-wrapper"
        //   data-layout="vertical"
        //   data-sidebartype="full"
        //   data-sidebar-position="fixed"
        //   data-header-position="fixed"
        // >
        //   <div className="position-relative overflow-hidden radial-gradient min-vh-100 d-flex align-items-center justify-content-center">
        //     <div className="d-flex align-items-center justify-content-center w-100">
        //   <div className="row justify-content-center w-100">
        // <div className="col-md-8 col-lg-6 col-xxl-3">
        // <div className="card mb-0 mx-auto" style={{maxWidth:'450px'}}>
        //     <div className="card-body">
        //         <Link to="/" className="text-nowrap logo-img text-center d-block mb-3 w-100">
        //             <img src="../../dist/images/logos/dark-logo.svg" width={180} alt="Logo" />
        //         </Link>

        // <form onSubmit={handleSubmit(onSubmit)}>
        //     {/* Current Password Field */}
        //     <div className="mb-3">
        //         <label htmlFor="currentPassword" className="form-label">Current Password</label>
        //         <div className="position-relative">
        //             <input
        //                 type={passwordVisible.currentPassword ? 'text' : 'password'}
        //                 className="form-control"
        //                 id="currentPassword"
        //                 {...register('currentPassword', { required: 'Current password is required' })}
        //             />
        //             <i
        //                 className={`ti ${passwordVisible.currentPassword ? 'ti-eye' : 'ti-eye-off'}`}
        //                 onClick={() => togglePasswordVisibility('currentPassword')}
        //                 style={{
        //                     position: 'absolute',
        //                     top: '50%',
        //                     right: '10px',
        //                     transform: 'translateY(-50%)',
        //                     cursor: 'pointer',
        //                     color: '#6c757d',
        //                     fontSize: '1.2rem',
        //                 }}
        //             ></i>
        //         </div>
        //         {errors.currentPassword && (
        //             <div className="error-message pt-1">
        //                 {errors.currentPassword.message}
        //             </div>
        //         )}
        //     </div>

        //     {/* New Password Field */}
        //     <div className="mb-4">
        //         <label htmlFor="newPassword" className="form-label">New Password</label>
        //         <div className="position-relative">
        //             <input
        //                 type={passwordVisible.newPassword ? 'text' : 'password'}
        //                 className="form-control"
        //                 id="newPassword"
        //                 {...register('newPassword', { required: 'New password is required' })}
        //             />
        //             <i
        //                 className={`ti ${passwordVisible.newPassword ? 'ti-eye' : 'ti-eye-off'}`}
        //                 onClick={() => togglePasswordVisibility('newPassword')}
        //                 style={{
        //                     position: 'absolute',
        //                     top: '50%',
        //                     right: '10px',
        //                     transform: 'translateY(-50%)',
        //                     cursor: 'pointer',
        //                     color: '#6c757d',
        //                     fontSize: '1.2rem',
        //                 }}
        //             ></i>
        //         </div>
        //         {errors.newPassword && (
        //             <div className="error-message pt-1">
        //                 {errors.newPassword.message}
        //             </div>
        //         )}
        //     </div>

        //     {/* Confirm Password Field */}
        //     <div className="mb-4">
        //         <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
        //         <div className="position-relative">
        //             <input
        //                 type={passwordVisible.confirmPassword ? 'text' : 'password'}
        //                 className="form-control"
        //                 id="confirmPassword"
        //                 {...register('confirmPassword', { required: 'Confirm password is required' })}
        //             />
        //             <i
        //                 className={`ti ${passwordVisible.confirmPassword ? 'ti-eye' : 'ti-eye-off'}`}
        //                 onClick={() => togglePasswordVisibility('confirmPassword')}
        //                 style={{
        //                     position: 'absolute',
        //                     top: '50%',
        //                     right: '10px',
        //                     transform: 'translateY(-50%)',
        //                     cursor: 'pointer',
        //                     color: '#6c757d',
        //                     fontSize: '1.2rem',
        //                 }}
        //             ></i>
        //         </div>
        //         {errors.confirmPassword && (
        //             <div className="error-message pt-1">
        //                 {errors.confirmPassword.message}
        //             </div>
        //         )}
        //     </div>

        //     {/* Submit Button */}
        //     <button type="submit" className="btn btn-primary w-100 py-8 mb-4 rounded-2">
        //         Update Password
        //     </button>
        // </form>
        //     </div>
        // </div>
        // </div>
        //    </div >
        //     </div>
        //   </div>
        // </div> 
    );
};

export default ChangePassword;
