import { Navigate } from "react-router-dom";
import Constant from "../config/constant";
import { decrypt } from "./encrypt.decrypt";

function NonProtectedRouts({ children }) {

    const encryptedUser = localStorage.getItem(Constant.AUTH_KEY) || '';
    const user = encryptedUser ? decrypt(encryptedUser) : null;

    if (encryptedUser) {
        if (user && user?.token) {
            return <Navigate to={'/'} replace />
        } else {
            localStorage.removeItem(Constant.AUTH_KEY);
            return children
        }
    }else{
        return children
    }


}

export default NonProtectedRouts;