import { Navigate, useLocation } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../Context/UserData";

function ProtectedRights({ children }) {
    const location = useLocation();
    const { role, rights } = useContext(UserContext); // Get role and rights from context

    const [isLoading, setIsLoading] = useState(true); // Loading state
    
    // Wait until role and rights are available
    useEffect(() => {
        if (role && rights) {
            setIsLoading(false);
        }
    }, [role, rights]);

    // Permission check logic based on the current path
    const checkPermissions = (path) => {
        if (role === 'super_admin') return true;

        if (role === 'sub_admin') {
            switch (path) {
                case "/no_access":
                case "/profile":
                    return true;
                    
                case "/":
                    return rights?.dashboard?.includes('listing');

                case "/customer":
                    return rights?.customer?.includes('listing');
                case "/customer/view":
                    return rights?.customer?.includes('view');

                case "/subscription":
                    return rights?.subscription?.includes('listing');
                case "/subscription/add":
                    return rights?.subscription?.includes('create');
                case "/subscription/view":
                    return rights?.subscription?.includes('view');
                case "/subscription/edit":
                    return rights?.subscription?.includes('update');

                case "/post":
                    return rights?.post?.includes('listing');
                case "/post/view":
                    return rights?.post?.includes('view');

                case "/earning":
                    return rights?.earning?.includes('listing');

                case "/notification":
                    return rights?.notification?.includes('listing');
                case "/notification/add":
                    return rights?.notification?.includes('create');

                case "/about_us":
                    return rights?.content_pages?.includes('listing');
                case "/privacy_policy":
                    return rights?.content_pages?.includes('listing');
                case "/terms_conditions":
                    return rights?.content_pages?.includes('listing');

                case "/faq":
                    return rights?.content_pages?.includes('listing');
                case "/faq/add":
                    return rights?.content_pages?.includes('create');
                    
                case "/contact_us":
                    return rights?.content_pages?.includes('listing');
                case "/report_user":
                    return rights?.report?.includes('listing');
                default:
                    return false;
            }
        }

        return false; // Default if no permissions match
    };

    if (isLoading) {
        return null; 
    }

    const hasAccess = checkPermissions(location.pathname);

    if (!hasAccess) {
        return <Navigate to="/no_access" replace />;
    }

    return children;
}

export default ProtectedRights;
