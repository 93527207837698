// import { TOAST_SUCCESS } from "../../config/common";
import * as API from "../../utils/api.services";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setLoader } from "./MasterSlice";

export const getSubAdminApiCall = createAsyncThunk("subAdminList", async (submitData, { dispatch }) => {
    try {
        dispatch(setLoader(true))
        const { data: subAdminList, code, message} = await API.getSubAdmins(submitData);
        dispatch(setLoader(false))
        return { data: subAdminList, code, message };
    } catch (error) {
        console.log(error);
        
        throw error;
    }
});

export const getModulesApiCall = createAsyncThunk("modulesList", async (submitData, { dispatch }) => {
    try {
        dispatch(setLoader(true))
        const { data: modulesList, code, message} = await API.getModules(submitData);
        dispatch(setLoader(false))
        return { data: modulesList, code, message };
    } catch (error) {
        console.log(error);
        
        throw error;
    }
});

// export const editProductDataApiCall = createAsyncThunk("editProductData", async (submitData, { dispatch }) => {
//     try {
//         dispatch(setLoader(true))
//         const { data: editProductData, code, message } = await API.editProductDataAPI(submitData);
//         if (code === '1') {
//             TOAST_SUCCESS(message);
//         }
//         dispatch(setLoader(false))
//         return { data: editProductData, code, message };
//     } catch (error) {
//         throw error;
//     }
// });

const initialState = {
    subAdminList: {
        data: [],
        code: '',
        message: '',
        error: null,
    },
    modulesList: {
        data: [],
        code: '',
        message: '',
        error: null,
    },
    // editProductData: {
    //     data: null,
    //     code: '',
    //     message: '',
    //     error: null,
    // },
};

const subAdminSlice = createSlice({
    name: "SUBADMIN",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getSubAdminApiCall.fulfilled, (state, action) => {
                const { message, code, data } = action.payload;
                state.subAdminList.data = data;
                state.subAdminList.message = message;
                state.subAdminList.code = code;
            })
            .addCase(getSubAdminApiCall.rejected, (state, action) => {
                state.subAdminList.error = action.error.message;
            })
            .addCase(getModulesApiCall.fulfilled, (state, action) => {
                const { message, code, data } = action.payload;
                state.modulesList.data = data;
                state.modulesList.message = message;
                state.modulesList.code = code;
            })
            .addCase(getModulesApiCall.rejected, (state, action) => {
                state.modulesList.error = action.error.message;
            })
    },
});

export default subAdminSlice.reducer;