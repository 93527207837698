import { BrowserRouter } from "react-router-dom";
import Router from "./Router/index";

function App() {
  return (
    <>
      <BrowserRouter basename="/">
        <Router />
      </BrowserRouter>
    </>)

}

export default App;
