// import { TOAST_SUCCESS } from "../../config/common";
import * as API from "../../utils/api.services";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setLoader } from "./MasterSlice";

export const getContentPageApiCall = createAsyncThunk("contentPage", async (submitData, { dispatch }) => {
    try {
        dispatch(setLoader(true))
        const { data: contentPage, code, message} = await API.getContentPage(submitData);
        dispatch(setLoader(false))
        return { data: contentPage, code, message };
    } catch (error) {
        console.log(error);
        
        throw error;
    }
});

export const getFaqApiCall = createAsyncThunk("faqList", async (submitData, { dispatch }) => {
    try {
        dispatch(setLoader(true))
        const { data: faqList, code, message} = await API.getFaqs(submitData);
        dispatch(setLoader(false))
        return { data: faqList, code, message };
    } catch (error) {
        console.log(error);
        
        throw error;
    }
});


export const getContactUsApiCall = createAsyncThunk("contactUsList", async (submitData, { dispatch }) => {
    try {
        dispatch(setLoader(true))
        const { data: contactUsList, code, message} = await API.getContactUs(submitData);
        dispatch(setLoader(false))
        return { data: contactUsList, code, message };
    } catch (error) {
        console.log(error);
        
        throw error;
    }
});

// export const editProductDataApiCall = createAsyncThunk("editProductData", async (submitData, { dispatch }) => {
//     try {
//         dispatch(setLoader(true))
//         const { data: editProductData, code, message } = await API.editProductDataAPI(submitData);
//         if (code === '1') {
//             TOAST_SUCCESS(message);
//         }
//         dispatch(setLoader(false))
//         return { data: editProductData, code, message };
//     } catch (error) {
//         throw error;
//     }
// });

const initialState = {
    contentPage: {
        data: [],
        code: '',
        message: '',
        error: null,
    },
    faqList: {
        data: [],
        code: '',
        message: '',
        error: null,
    },
    contactUsList: {
        data: [],
        code: '',
        message: '',
        error: null,
    },
    // editProductData: {
    //     data: null,
    //     code: '',
    //     message: '',
    //     error: null,
    // },
};

const contentPageSlice = createSlice({
    name: "CONTENTPAGE",   
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getContentPageApiCall.fulfilled, (state, action) => {
                const { message, code, data } = action.payload;
                state.contentPage.data = data;
                state.contentPage.message = message;
                state.contentPage.code = code;
            })
            .addCase(getContentPageApiCall.rejected, (state, action) => {
                state.contentPage.error = action.error.message;
            })
            .addCase(getFaqApiCall.fulfilled, (state, action) => {
                const { message, code, data } = action.payload;
                state.faqList.data = data;
                state.faqList.message = message;
                state.faqList.code = code;
            })
            .addCase(getFaqApiCall.rejected, (state, action) => {
                state.faqList.error = action.error.message;
            })
            .addCase(getContactUsApiCall.fulfilled, (state, action) => {
                const { message, code, data } = action.payload;
                state.contactUsList.data = data;
                state.contactUsList.message = message;
                state.contactUsList.code = code;
            })
            .addCase(getContactUsApiCall.rejected, (state, action) => {
                state.contactUsList.error = action.error.message;
            })
    },
});

export default contentPageSlice.reducer;