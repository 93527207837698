// import { TOAST_SUCCESS } from "../../config/common";
import * as API from "../../utils/api.services";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setLoader } from "./MasterSlice";

export const getNotificationsApiCall = createAsyncThunk("notificationsList", async (submitData, { dispatch }) => {
    try {
        dispatch(setLoader(true))
        const { data: notificationsList, code, message} = await API.getNotifications(submitData);
        dispatch(setLoader(false))
        return { data: notificationsList, code, message };
    } catch (error) {
        console.log(error);
        
        throw error;
    }
});

export const getUsersApiCall = createAsyncThunk("usersList", async (submitData, { dispatch }) => {
    try {
        dispatch(setLoader(true))
        const { data: usersList, code, message} = await API.getUsers(submitData);
        dispatch(setLoader(false))
        return { data: usersList, code, message };
    } catch (error) {
        console.log(error);
        
        throw error;
    }
});

const initialState = {
    notificationsList: {
        data: [],
        code: '',
        message: '',
        error: null,
    },
    usersList: {
        data: [],
        code: '',
        message: '',
        error: null,
    },

};

const notificationSlice = createSlice({
    name: "NOTIFICATION",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getNotificationsApiCall.fulfilled, (state, action) => {
                const { message, code, data } = action.payload;
                state.notificationsList.data = data;
                state.notificationsList.message = message;
                state.notificationsList.code = code;
            })
            .addCase(getNotificationsApiCall.rejected, (state, action) => {
                state.notificationsList.error = action.error.message;
            })

            .addCase(getUsersApiCall.fulfilled, (state, action) => {
                const { message, code, data } = action.payload;
                state.usersList.data = data;
                state.usersList.message = message;
                state.usersList.code = code;
            })
            .addCase(getUsersApiCall.rejected, (state, action) => {
                state.usersList.error = action.error.message;
            })
    },
});

export default notificationSlice.reducer;