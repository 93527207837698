import { createContext, useEffect, useState } from "react";
import Constant from "../config/constant";
import { decrypt } from "../utils/encrypt.decrypt";

export const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  const [rights, setRights] = useState({});
  const [role, setRole] = useState('');
  const [user, setUser] = useState({});

  useEffect(() => {
    const encryptedUser = localStorage.getItem(Constant.AUTH_KEY) || '';
    const user = encryptedUser ? decrypt(encryptedUser) : null;

    if (encryptedUser) {
        if (user && user?.token) {
            setRights(user?.module_permissions)
            setRole(user?.role)
            delete user?.module_permissions
            setUser(user)
        } 
    }
  }, []);

  const updateUser = () => {
    const encryptedUser = localStorage.getItem(Constant.AUTH_KEY) || '';
    const user = encryptedUser ? decrypt(encryptedUser) : null;

    if (encryptedUser) {
        if (user && user?.token) {
            setRights(user?.module_permissions)
            setRole(user?.role)
            delete user?.module_permissions
            setUser(user)
        } 
    }
  };


  return (
    <UserContext.Provider value={{ rights, role, user, updateUser}}>
      {children}
    </UserContext.Provider>
  );
};
