// import { TOAST_SUCCESS } from "../../config/common";
import * as API from "../../utils/api.services";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setLoader } from "./MasterSlice";

export const getSubscriptionApiCall = createAsyncThunk("subscriptionList", async (submitData, { dispatch }) => {
    try {
        dispatch(setLoader(true))
        const { data: subscriptionList, code, message} = await API.getSubscription(submitData);
        dispatch(setLoader(false))
        return { data: subscriptionList, code, message };
    } catch (error) {
        console.log(error);
        
        throw error;
    }
});

export const getEarningsApiCall = createAsyncThunk("earningList", async (submitData, { dispatch }) => {
    try {
        dispatch(setLoader(true))
        const { data: earningList, code, message} = await API.getEarnings(submitData);
        dispatch(setLoader(false))
        return { data: earningList, code, message };
    } catch (error) {
        console.log(error);
        
        throw error;
    }
});

// export const editProductDataApiCall = createAsyncThunk("editProductData", async (submitData, { dispatch }) => {
//     try {
//         dispatch(setLoader(true))
//         const { data: editProductData, code, message } = await API.editProductDataAPI(submitData);
//         if (code === '1') {
//             TOAST_SUCCESS(message);
//         }
//         dispatch(setLoader(false))
//         return { data: editProductData, code, message };
//     } catch (error) {
//         throw error;
//     }
// });

const initialState = {
    subscriptionList: {
        data: [],
        code: '',
        message: '',
        error: null,
    },
    earningList: {
        data: [],
        code: '',
        message: '',
        error: null,
    },
    // editProductData: {
    //     data: null,
    //     code: '',
    //     message: '',
    //     error: null,
    // },
};

const subscriptionSlice = createSlice({
    name: "SUBSCRIPTION",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getSubscriptionApiCall.fulfilled, (state, action) => {
                const { message, code, data } = action.payload;
                state.subscriptionList.data = data;
                state.subscriptionList.message = message;
                state.subscriptionList.code = code;
            })
            .addCase(getSubscriptionApiCall.rejected, (state, action) => {
                state.subscriptionList.error = action.error.message;
            })
            .addCase(getEarningsApiCall.fulfilled, (state, action) => {
                const { message, code, data } = action.payload;
                state.earningList.data = data;
                state.earningList.message = message;
                state.earningList.code = code;
            })
            .addCase(getEarningsApiCall.rejected, (state, action) => {
                state.earningList.error = action.error.message;
            })
    },
});

export default subscriptionSlice.reducer;