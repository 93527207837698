import React from 'react'
import Sidebar from './sidebar'
import Navbar from './navbar'
import { Outlet } from 'react-router-dom'
import ProtectedRouts from '../utils/protected.routs'
import ProtectedRights from '../utils/protected.rights'

const DashboardLayout = ({ children }) => {

    return (
        <>
            <ProtectedRouts >
                <div
                    className="page-wrapper"
                    id="main-wrapper"
                    data-layout="vertical"
                    data-sidebartype="full"
                    data-sidebar-position="fixed"
                    data-header-position="fixed"
                >

                    <Sidebar />
                    <div className="body-wrapper">
                        <Navbar />
                        <div className="container-fluid" style={{ maxWidth:"100%" }}>
                            <ProtectedRights >
                                {children}
                                <Outlet />
                            </ProtectedRights>
                        </div>
                    </div>
                </div>
            </ProtectedRouts>
        </>
    )
}

export default DashboardLayout
