import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Constant from '../../config/constant';
import { UserContext } from '../../Context/UserData';

const Sidebar = () => {
  const location = useLocation();
  const { role, rights } = useContext(UserContext);

  return (
    <aside className="left-sidebar">
      <div>
        <div className="brand-logo d-flex align-items-center justify-content-between">
          <Link to="/" className="text-nowrap logo-img">
            <img
              src={Constant.APP_LOGO}
              className="dark-logo"
              width={200}
              alt="Dark Logo"
            />
          </Link>
          <div
            className="close-btn d-lg-none d-block sidebartoggler cursor-pointer"
            id="sidebarCollapse"
          >
            <i className="ti ti-x fs-8 text-muted" />
          </div>
        </div>
        {/* Sidebar navigation */}
        <nav className="sidebar-nav scroll-sidebar" data-simplebar="">
          <ul id="sidebarnav">

            {/* Dashboard  */}
            {(role === 'super_admin' || (role === 'sub_admin' && rights?.dashboard?.includes('listing'))) && (
              <li className={`sidebar-item ${location.pathname === '/' ? 'selected' : ''}`}>
                <Link className={`sidebar-link ${location.pathname === '/' ? 'active' : ''}`} to="/" aria-expanded="false">
                  <span>
                    <i className="ti ti-dashboard" />
                  </span>
                  <span className="hide-menu">Dashboard</span>
                </Link>
              </li>
            )}

            {/* Customer  */}
            {(role === 'super_admin' || (role === 'sub_admin' && rights?.customer?.includes('listing'))) && (
              <li className={`sidebar-item ${location.pathname.startsWith('/customer') ? 'selected' : ''}`}>
                <Link className={`sidebar-link ${location.pathname.startsWith('/customer') ? 'active' : ''}`} to="/customer" aria-expanded="false">
                  <span>
                    <i className="ti ti-users" />
                  </span>
                  <span className="hide-menu">Customer</span>
                </Link>
              </li>
            )}

            {/* Subscription  */}
            {(role === 'super_admin' || (role === 'sub_admin' && rights?.subscription?.includes('listing'))) && (
              <li className={`sidebar-item ${location.pathname.startsWith('/subscription') ? 'selected' : ''}`}>
                <Link className={`sidebar-link ${location.pathname.startsWith('/subscription') ? 'active' : ''}`} to="/subscription" aria-expanded="false">
                  <span>
                    <i className="ti ti-package" />
                  </span>
                  <span className="hide-menu">Subscription</span>
                </Link>
              </li>
            )}

            {/* Post  */}
            {(role === 'super_admin' || (role === 'sub_admin' && rights?.post?.includes('listing'))) && (
              <li className={`sidebar-item ${location.pathname.startsWith('/post') ? 'selected' : ''}`}>
                <Link className={`sidebar-link ${location.pathname.startsWith('/post') ? 'active' : ''}`} to="/post" aria-expanded="false">
                  <span>
                    <i className="ti ti-pencil" />
                  </span>
                  <span className="hide-menu">Post</span>
                </Link>
              </li>
            )}

            {/* Sub Admin  */}
            {(role === 'super_admin') && (
              <li className={`sidebar-item ${location.pathname.startsWith('/sub_admin') ? 'selected' : ''}`}>
                <Link className={`sidebar-link ${location.pathname.startsWith('/sub_admin') ? 'active' : ''}`} to="/sub_admin" aria-expanded="false">
                  <span>
                    <i className="ti ti-user" />
                  </span>
                  <span className="hide-menu">Sub Admin</span>
                </Link>
              </li>
            )}

            {/* Earning  */}
            {(role === 'super_admin' || (role === 'sub_admin' && rights?.earning?.includes('listing'))) && (
              <li className={`sidebar-item ${location.pathname.startsWith('/earning') ? 'selected' : ''}`}>
                <Link className={`sidebar-link ${location.pathname.startsWith('/earning') ? 'active' : ''}`} to="/earning" aria-expanded="false">
                  <span>
                    <i className="ti ti-wallet" />
                  </span>
                  <span className="hide-menu">Earning</span>
                </Link>
              </li>
            )}

            {/* Generate  Reports*/}
            {/* {(role === 'super_admin' || (role === 'sub_admin' && rights?.earning?.includes('listing'))) && ( */}
              <li className={`sidebar-item ${location.pathname.startsWith('/generate_reports') ? 'selected' : ''}`}>
                <Link className={`sidebar-link ${location.pathname.startsWith('/generate_reports') ? 'active' : ''}`} to="/generate_reports" aria-expanded="false">
                  <span>
                    <i className="ti ti-report" />
                  </span>
                  <span className="hide-menu">Generate Reports</span>
                </Link>
              </li>
            {/* )} */}

            {/* Notification  */}
            {(role === 'super_admin' || (role === 'sub_admin' && rights?.notification?.includes('listing'))) && (
              <li className={`sidebar-item ${location.pathname.startsWith('/notification') ? 'selected' : ''}`}>
                <Link className={`sidebar-link ${location.pathname.startsWith('/notification') ? 'active' : ''}`} to="/notification" aria-expanded="false">
                  <span>
                    <i className="ti ti-bell" />
                  </span>
                  <span className="hide-menu">Notification</span>
                </Link>
              </li>
            )}

            {/* reported user  */}
            {(role === 'super_admin' || (role === 'sub_admin' && rights?.report?.includes('listing'))) && (
              <li className={`sidebar-item ${location.pathname.startsWith('/report_user') ? 'selected' : ''}`}>
                <Link className={`sidebar-link ${location.pathname.startsWith('/report_user') ? 'active' : ''}`} to="/report_user" aria-expanded="false">
                  <span>
                    <i className="ti ti-flag" />
                  </span>
                  <span className="hide-menu">Reported User</span>
                </Link>
              </li>
            )}

            {/* CMS Pages */}
            {(role === 'super_admin' || (role === 'sub_admin' && rights?.content_pages?.includes('listing'))) && (
              <>
                <li className={`sidebar-item ${location.pathname === '/about_us' ? 'selected' : ''}`}>
                  <Link to="/about_us" className={`sidebar-link ${location.pathname === '/about_us' ? 'active' : ''}`}>
                    <div className="round-16 d-flex align-items-center justify-content-center">
                      <i className="ti ti-info-circle"></i>
                    </div>
                    <span className="hide-menu">About Us</span>
                  </Link>
                </li>
                <li className={`sidebar-item ${location.pathname === '/privacy_policy' ? 'selected' : ''}`}>
                  <Link to="/privacy_policy" className={`sidebar-link ${location.pathname === '/privacy_policy' ? 'active' : ''}`}>
                    <div className="round-16 d-flex align-items-center justify-content-center">
                      <i className="ti ti-lock"></i>
                    </div>
                    <span className="hide-menu">Privacy Policy</span>
                  </Link>
                </li>
                <li className={`sidebar-item ${location.pathname === '/terms_conditions' ? 'selected' : ''}`}>
                  <Link to="/terms_conditions" className={`sidebar-link ${location.pathname === '/terms_conditions' ? 'active' : ''}`}>
                    <div className="round-16 d-flex align-items-center justify-content-center">
                      <i className="ti ti-file"></i>
                    </div>
                    <span className="hide-menu">Terms & Conditions</span>
                  </Link>
                </li>
                <li className={`sidebar-item ${location.pathname.startsWith('/faq') ? 'selected' : ''}`}>
                  <Link to="/faq" className={`sidebar-link ${location.pathname.startsWith('/faq') ? 'active' : ''}`}>
                    <div className="round-16 d-flex align-items-center justify-content-center">
                      <i className="ti ti-help"></i>
                    </div>
                    <span className="hide-menu">FAQ</span>
                  </Link>
                </li>
                <li className={`sidebar-item ${location.pathname === '/contact_us' ? 'selected' : ''}`}>
                  <Link to="/contact_us" className={`sidebar-link ${location.pathname === '/contact_us' ? 'active' : ''}`}>
                    <div className="round-16 d-flex align-items-center justify-content-center">
                      <i className="fa fa-envelope"></i>
                    </div>
                    <span className="hide-menu">Contact Us</span>
                  </Link>
                </li>
              </>
            )}

          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default Sidebar;
