import { configureStore } from "@reduxjs/toolkit";
import MasterSlice from "./slices/MasterSlice";
import customerSlice from "./slices/customerSlice";
import subscriptionSlice from "./slices/subscriptionSlice";
import postSlice from "./slices/postSlice";
import subAdminSlice from "./slices/subAdminSlice";
import notificationSlice from "./slices/notificationSlice";
import contentPageSlice from "./slices/contentPageSlice";
import reportSlice from "./slices/reportSlice";


const store = configureStore({
    reducer: {
        masterSlice: MasterSlice,
        customer: customerSlice,
        subscription: subscriptionSlice,
        post : postSlice,
        subAdmin : subAdminSlice,
        notification : notificationSlice,
        ContentPage :contentPageSlice,
        report : reportSlice
    },
});

export default store;
