import { Routes, Route } from 'react-router-dom';
import React, { lazy, Suspense } from 'react';
import DashboardLayout from '../layout';
import NonProtectedRouts from '../utils/nonprotected.routs';
import { useSelector } from 'react-redux';

export const Loadable = (Component) => (props) => {
    return (
        <Suspense>
            <Component {...props} />
        </Suspense>
    );
};

const Spinner = Loadable(lazy(() => import("../components/Spinner")));
const Login = Loadable(lazy(() => import("../pages/auth/Login")));
const Profile = Loadable(lazy(() => import("../pages/auth/Profile")));
const ForgotPass = Loadable(lazy(() => import("../pages/auth/ForgotPass")));
const ResetPassword = Loadable(lazy(() => import("../pages/auth/ResetPassword")));

const Dashboard = Loadable(lazy(() => import("../pages/dashboard")));

const Customer = Loadable(lazy(() => import("../pages/customer")));
const ViewCustomerDetail = Loadable(lazy(() => import("../pages/customer/View")));
const ReportUsers = Loadable(lazy(() => import("../pages/report_users/index")));

const Subscription = Loadable(lazy(() => import("../pages/subscription")));
const ViewSubscriptionDetail = Loadable(lazy(() => import("../pages/subscription/View")));
const EditSubscription = Loadable(lazy(() => import("../pages/subscription/Edit")));
const AddSubscription = Loadable(lazy(() => import("../pages/subscription/Add")));

const Post = Loadable(lazy(() => import("../pages/post")));
const ViewPostDetail = Loadable(lazy(() => import("../pages/post/View")));

const SubAdmin = Loadable(lazy(() => import("../pages/sub_admin")));
const AddSubAdmin = Loadable(lazy(() => import("../pages/sub_admin/Add")));
const EditSubAdmin = Loadable(lazy(() => import("../pages/sub_admin/Edit")));
const ViewSubAdmin = Loadable(lazy(() => import("../pages/sub_admin/View")));

const Earning = Loadable(lazy(() => import("../pages/earnings")));

const Notification = Loadable(lazy(() => import("../pages/notifications")));
const AddNotification = Loadable(lazy(() => import("../pages/notifications/Add")));

const ContentPage = Loadable(lazy(() => import("../pages/cms")));
const Faq = Loadable(lazy(() => import("../pages/cms/Faq")));
const AddFaq = Loadable(lazy(() => import("../pages/cms/FaqAdd")));
const ContactUs = Loadable(lazy(() => import("../pages/cms/ContactUs")));

const PageNotFound = Loadable(lazy(() => import("./PageNotFound")))
const NoAccess = Loadable(lazy(() => import("./NoAccess")))

const GenerateReport = Loadable(lazy(() => import("../pages/generate_reports/index")))

const Router = () => {
    const { isLoading } = useSelector((state) => state.masterSlice)

    return (
        <>
            <Spinner isActive={isLoading} message={'Please Wait...'} />
            <Routes>
                <Route path="/login" element={<NonProtectedRouts><Login /></NonProtectedRouts>} />
                <Route path="/forgot_password" element={<NonProtectedRouts><ForgotPass /></NonProtectedRouts>} />
                <Route path="/reset_password/:token" element={<NonProtectedRouts><ResetPassword /></NonProtectedRouts>} />

                <Route element={<DashboardLayout />}>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/profile" element={<Profile />} />

                    <Route path="/customer" element={<Customer />} />
                    <Route path="/customer/view" element={<ViewCustomerDetail />} />

                    <Route path="/report_user" element={<ReportUsers />} />

                    <Route path="/subscription" element={<Subscription />} />
                    <Route path="/subscription/view" element={<ViewSubscriptionDetail />} />
                    <Route path="/subscription/edit" element={<EditSubscription />} />
                    <Route path="/subscription/add" element={<AddSubscription />} />

                    <Route path="/post" element={<Post />} />
                    <Route path="/post/view" element={<ViewPostDetail />} />

                    <Route path="/sub_admin" element={<SubAdmin />} />
                    <Route path="/sub_admin/add" element={<AddSubAdmin />} />
                    <Route path="/sub_admin/edit" element={<EditSubAdmin />} />
                    <Route path="/sub_admin/view" element={<ViewSubAdmin />} />

                    <Route path="/earning" element={<Earning />} />

                    <Route path="/notification" element={<Notification />} />
                    <Route path="/notification/add" element={<AddNotification />} />

                    <Route path="/about_us" element={<ContentPage />} />
                    <Route path="/privacy_policy" element={<ContentPage />} />
                    <Route path="/terms_conditions" element={<ContentPage />} />

                    <Route path="/faq" element={<Faq />} />
                    <Route path="/faq/add" element={<AddFaq />} />

                    <Route path="/contact_us" element={<ContactUs />} />
                    <Route path="/generate_reports" element={<GenerateReport />} />

                    <Route path="/no_access" element={<NoAccess />} />

                </Route>

                <Route path="*" element={<PageNotFound />} />

            </Routes>
        </>
    )


}

export default Router;
