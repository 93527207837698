import { Navigate } from "react-router-dom";
import Constant from "../config/constant";
import { decrypt } from "./encrypt.decrypt";

function ProtectedRouts({ children }) {

    const encryptedUser = localStorage.getItem(Constant.AUTH_KEY) || '';
    const user = encryptedUser ? decrypt(encryptedUser) : null;

    if (encryptedUser) {
        if (user && user?.token) {
            return children;
        } else {
            localStorage.removeItem(Constant.AUTH_KEY);
            return <Navigate to={'/login'} replace />
        }
    }else{
        return <Navigate to={'/login'} replace />
    }

}

export default ProtectedRouts;