const Constant = {

    APP_NAME: process.env.REACT_APP_NAME,
    API_KEY: process.env.REACT_APP_API_KEY,
    // API_BASE_URL: process.env.REACT_APP_API_URL,
    API_BASE_URL: process.env.REACT_APP_API_URL_LIVE,
    KEY: process.env.REACT_APP_ADMIN_KEY,
    IV: process.env.REACT_APP_ADMIN_IV,
    APP_BASE_URL: process.env.REACT_APP_BASE_URL,
    APP_LOGO : `${process.env.PUBLIC_URL}/dist/images/logos/app_logo.png`,
    PUBLIC_URL :  process.env.PUBLIC_URL,

    // ---------------------------Code manage---------------------------------------

    SUCCESS: '1',
    INVALID_OR_FAIL: '0',
    NO_DATA_FOUND: '2',
    DELETE_ACCOUNT: '3',
    USER_SESSION_EXPIRE: '-1',

    // --------------------------Local storage credentials-----------------------------------------

    AUTH_KEY: 'user',
    LANGUAGE_KEY: 'language',
    THEME_KEY: 'theme'

}

export const SEARCH_DELAY = 500;
export const API_DELAY = 500;
export const PER_PAGE_DATA = 10;
export const COUNT_PER_PAGE = 10;

export const Codes = {
    SUCCESS: 1,
    INTERNAL_ERROR: 0,
    VALIDATION_ERROR: 0,
    UNAUTHORIZED: -1,
    INACTIVE: 3,
    NOT_FOUND: 2,
    ERROR: 0
}

export default Constant