import { fetchWrapper } from "../utils/axios.services";


const ADMIN = '/admin'
const AUTH = '/auth'
const POST = '/post'
const CUSTOMER = '/customer'
const SUBSCRIPTION = '/subscription'
const SUBADMIN = '/sub_admin'
const EARNING = '/earning'
const NOTIFICATION = '/notification'
const CONTENTPAGE = '/content_pages'
const DASHBOARD = '/dashboard'
const EXPORT = '/export'


//auth
export function login(data) {
    return fetchWrapper(`${ADMIN}${AUTH}/login`, data, 'POST');
}

export function logout() {
    return fetchWrapper(`${ADMIN}${AUTH}/logout`, {}, 'POST');
}

export function changePassword(data) {
    return fetchWrapper(`${ADMIN}${AUTH}/update_password`, data, 'POST');
}

export function updateProfile(data) {
    return fetchWrapper(`${ADMIN}${AUTH}/update_profile`, data, 'POST');
}

export function forgotPassword(data) {
    return fetchWrapper(`${ADMIN}${AUTH}/forgot_password`, data, 'POST');
}

export function verifyForgotLink(data) {
    return fetchWrapper(`${ADMIN}${AUTH}/verify_token`, data, 'POST');
}

export function resetPassword(data) {
    return fetchWrapper(`${ADMIN}${AUTH}/reset_password`, data, 'POST');
}


//customer
export function getCustomers(data) {
    return fetchWrapper(`${ADMIN}${CUSTOMER}/customer_listing`, data, 'POST');
}
export function getCustomersDetail(data) {
    return fetchWrapper(`${ADMIN}${CUSTOMER}/selected_customer_details`, data, 'POST');
}

export function deleteCustomers(data) {
    return fetchWrapper(`${ADMIN}${CUSTOMER}/delete_customer`, data, 'POST');
}

export function changeCustomerStatus(data) {
    return fetchWrapper(`${ADMIN}${CUSTOMER}/block_unblock_customer`, data, 'POST');
}

export function getCustomerPosts(data) {
    return fetchWrapper(`${ADMIN}${CUSTOMER}/listing_customer_posts`, data, 'POST');
}

export function getFollowersFollowing(data) {
    return fetchWrapper(`${ADMIN}${CUSTOMER}/listing_followers_following`, data, 'POST');
}


//Subscription
export function addSubscription(data) {
    return fetchWrapper(`${ADMIN}${SUBSCRIPTION}/add_subscription`, data, 'POST');
}

export function getSubscription(data) {
    return fetchWrapper(`${ADMIN}${SUBSCRIPTION}/subscription_listing`, data, 'POST');
}

export function deleteSubscription(data) {
    return fetchWrapper(`${ADMIN}${SUBSCRIPTION}/delete_subscription`, data, 'POST');
}

export function changeSubscriptionStatus(data) {
    return fetchWrapper(`${ADMIN}${SUBSCRIPTION}/block_unblock_subscription`, data, 'POST');
}

export function updateSubscription(data) {
    return fetchWrapper(`${ADMIN}${SUBSCRIPTION}/update_subscription`, data, 'POST');
}


//post
export function getPosts(data) {
    return fetchWrapper(`${ADMIN}${POST}/listing`, data, 'POST');
}

export function deletePosts(data) {
    return fetchWrapper(`${ADMIN}${POST}/delete`, data, 'POST');
}

export function changePostStatus(data) {
    return fetchWrapper(`${ADMIN}${POST}/active_inactive`, data, 'POST');
}

export function getPostsDetail( data ) {
    return fetchWrapper(`${ADMIN}${POST}/detail_view`, data, 'POST');
}

export function getPostsComments( data ) {
    return fetchWrapper(`${ADMIN}${POST}/listing_comments`, data, 'POST');
}

export function getPostsLikes( data ) {
    return fetchWrapper(`${ADMIN}${POST}/listing_likes`, data, 'POST');
}


//report
export function getReportList( data ) {
    return fetchWrapper(`${ADMIN}${POST}/report_list`, data, 'POST');
}

export function getReportUser( data ) {
    return fetchWrapper(`${ADMIN}${CUSTOMER}/listing_reported_user`, data, 'POST');
}

//sub admin
export function getSubAdmins( data ) {
    return fetchWrapper(`${ADMIN}${SUBADMIN}/sub_admin_listing`, data, 'POST');
}

export function addSubAdmin( data ) {
    return fetchWrapper(`${ADMIN}${SUBADMIN}/add_sub_admin`, data, 'POST');
}

export function updateSubAdmin( data ) {
    return fetchWrapper(`${ADMIN}${SUBADMIN}/update_sub_admin_profile`, data, 'POST');
}

export function deleteSubAdmin( data ) {
    return fetchWrapper(`${ADMIN}${SUBADMIN}/delete_sub_admin`, data, 'POST');
}
//modules listing
export function getModules( data ) {
    return fetchWrapper(`${ADMIN}${SUBADMIN}/modules_listing`, data, 'POST');
}

//earning listing
export function getEarnings( data ) {
    return fetchWrapper(`${ADMIN}${EARNING}/listing`, data, 'POST');
}


//notifications
export function getNotifications( data ) {
    return fetchWrapper(`${ADMIN}${NOTIFICATION}/listing`, data, 'POST');
}
export function getUsers( data ) {
    return fetchWrapper(`${ADMIN}${NOTIFICATION}/user_listing`, data, 'POST');
}
export function addNotifications( data ) {
    return fetchWrapper(`${ADMIN}${NOTIFICATION}/add`, data, 'POST');
}

//content pages
export function getContentPage( data ) {
    return fetchWrapper(`${ADMIN}${CONTENTPAGE}/view`, data, 'POST');
}
export function editContentPage( data ) {
    return fetchWrapper(`${ADMIN}${CONTENTPAGE}/edit`, data, 'POST');
}
export function getFaqs( data ) {
    return fetchWrapper(`${ADMIN}${CONTENTPAGE}/faq_listing`, data, 'POST');
}
export function addFaq( data ) {
    return fetchWrapper(`${ADMIN}${CONTENTPAGE}/faq_add`, data, 'POST');
}

export function getContactUs( data ) {
    return fetchWrapper(`${ADMIN}${CONTENTPAGE}/contact_us_listing`, data, 'POST');
}

//dashboard
export function getDashboardMatrix( data ) {
    return fetchWrapper(`${ADMIN}${DASHBOARD}/listing`, data, 'POST');
}



//export data
export function exportCustomer( data ) {
    return fetchWrapper(`${ADMIN}${EXPORT}/customer_table_report`, data, 'POST');
}
export function exportSubscription( data ) {
    return fetchWrapper(`${ADMIN}${EXPORT}/subscription_table_report`, data, 'POST');
}
export function generateReport( data ) {
    return fetchWrapper(`${ADMIN}${EXPORT}/generate_report`, data, 'POST');
}