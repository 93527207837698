// import { TOAST_SUCCESS } from "../../config/common";
import * as API from "../../utils/api.services";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setLoader } from "./MasterSlice";

export const getPostApiCall = createAsyncThunk("postList", async (submitData, { dispatch }) => {
    try {
        dispatch(setLoader(true))
        const { data: postList, code, message} = await API.getPosts(submitData);
        dispatch(setLoader(false))
        return { data: postList, code, message };
    } catch (error) {
        console.log(error);
        
        throw error;
    }
});

// export const editProductDataApiCall = createAsyncThunk("editProductData", async (submitData, { dispatch }) => {
//     try {
//         dispatch(setLoader(true))
//         const { data: editProductData, code, message } = await API.editProductDataAPI(submitData);
//         if (code === '1') {
//             TOAST_SUCCESS(message);
//         }
//         dispatch(setLoader(false))
//         return { data: editProductData, code, message };
//     } catch (error) {
//         throw error;
//     }
// });

const initialState = {
    postList: {
        data: [],
        code: '',
        message: '',
        error: null,
    },
    // editProductData: {
    //     data: null,
    //     code: '',
    //     message: '',
    //     error: null,
    // },
};

const postSlice = createSlice({
    name: "POST",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPostApiCall.fulfilled, (state, action) => {
                const { message, code, data } = action.payload;
                state.postList.data = data;
                state.postList.message = message;
                state.postList.code = code;
            })
            .addCase(getPostApiCall.rejected, (state, action) => {
                state.postList.error = action.error.message;
            })
    },
});

export default postSlice.reducer;