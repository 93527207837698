// import { TOAST_SUCCESS } from "../../config/common";
import * as API from "../../utils/api.services";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setLoader } from "./MasterSlice";

export const getReportedUsers = createAsyncThunk("reportUserList", async (submitData, { dispatch }) => {
    try {
        dispatch(setLoader(true))
        const { data: reportUserList, code, message } = await API.getReportUser(submitData);
        dispatch(setLoader(false))
        return { data: reportUserList, code, message };
    } catch (error) {
        console.log(error);

        throw error;
    }
});



const initialState = {
    reportUserList: {
        data: [],
        code: '',
        message: '',
        error: null,
    },
};

const reportSlice = createSlice({
    name: "REPORT",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getReportedUsers.fulfilled, (state, action) => {
                const { message, code, data } = action.payload;
                state.reportUserList.data = data;
                state.reportUserList.message = message;
                state.reportUserList.code = code;
            })
            .addCase(getReportedUsers.rejected, (state, action) => {
                state.reportUserList.error = action.error.message;
            })
    },
});

export default reportSlice.reducer;